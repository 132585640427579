/* eslint-disable no-shadow */
/* eslint-disable global-require */

import { PropertyRolePermission } from '@/store/modules/interfaces';

import { CollectionType, MetricAggregations } from '../measurements/interfaces';

export { PropertyRolePermission } from '@/store/modules/interfaces';

export interface UnitDevice {
    id: string;
    name: string;
    orgId: string;
    createdAt: string;
}

// eslint-disable-next-line no-shadow
export enum IntegrationType {
    BIDRENTO = 'bidrento',
    BIDRENTO_HOTEL = 'bidrento_hotel',
    MEWS = 'mews',
    ESO_V2 = 'eso_v2',
    SAAGO = 'saago',
    MODERAN = 'moderan',
    ELERING = 'elering',
    OPEN_METEO = 'open_meteo',
  }

export interface Integration {
    id: string;
    orgId: string;
    propertyId: string;
    externalId?: string;
    externalData: Record<string, any>;
    type: IntegrationType;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
}

export function integrationTypeImage(type: IntegrationType) {
  switch (type) {
    case IntegrationType.BIDRENTO:
    case IntegrationType.BIDRENTO_HOTEL:
      return require('@/assets/bidrento-logo.svg');
    case IntegrationType.ESO_V2:
      return require('@/assets/eso-logo.svg');
    case IntegrationType.MEWS:
      return require('@/assets/mews-logo.svg');
    case IntegrationType.SAAGO:
      return require('@/assets/saago-logo.svg');
    case IntegrationType.MODERAN:
      return require('@/assets/moderan-logo.svg');
    case IntegrationType.ELERING:
      return require('@/assets/elering-logo.svg');
    case IntegrationType.OPEN_METEO:
      return require('@/assets/open-meteo-logo.svg');
    default: return null;
  }
}

export function integrationConnectionMicroservices(type: IntegrationType): ('mantis'|'measurements'|'beetle')[] {
  switch (type) {
    case IntegrationType.SAAGO:
      return ['beetle'];
    case IntegrationType.BIDRENTO:
      return ['mantis', 'measurements'];
    case IntegrationType.BIDRENTO_HOTEL:
    case IntegrationType.MODERAN:
    case IntegrationType.MEWS:
      return ['mantis'];
    case IntegrationType.ESO_V2:
    case IntegrationType.ELERING:
    case IntegrationType.OPEN_METEO:
      return ['measurements'];
    default: return [];
  }
}

export enum ServiceConnectionType {
  MANTIS = 'mantis',
  MEASUREMENTS = 'measurements',
  HIVE = 'hive',
  BEETLE = 'beetle',
  MANTIS_LARVA_BROKER = 'mantis_larva_broker',
  MEASUREMENTS_LARVA_BROKER = 'measurements_larva_broker',
  HIVE_LARVA_BROKER = 'hive_larva_broker'
}

export interface ServiceConnection {
  id: string;
  orgId: string;
  propertyId: string;
  integrationId?: string | null;
  externalId?: string | null;
  externalData?: Record<string, any> | null;
  type: ServiceConnectionType;
  name?: string | null;
  connected?: boolean;
  lastError?: string | null;
  lockTime?: string;
  autoSchedulePinSMS?: boolean;
  autoSchedulePinEmail?: boolean;
  enabled?: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum MewsReservationState {
  Enquired = 'Enquired',
  Requested = 'Requested',
  Optional = 'Optional',
  Confirmed = 'Confirmed',
  Started = 'Started',
  Processed = 'Processed',
  Canceled = 'Canceled',
}

export interface BidrentoBuilding {
  id: number;
  identifier: string;
  timezone: string;
  // eslint-disable-next-line camelcase
  house_number?: string;
  street?: string;
  city?: string;
  country?: string;
}

export interface BidrentoHotelProperty {
  uuid: string;
  name: string;
}

export interface ModeranPropertySet {
  id: number;
  name: string;
}

export interface BidrentoRentalObject {
  id: number,
  identifier: string,
  country?: string,
  city: string,
  street: string,
  house_number: string,
  apartment_number: string | null,
  room_number: string | null,
  building?: BidrentoBuilding | null | undefined,
  rental_type: string,
  target_rent: null | string,
  county: string,
  floors: number | null,
  floor: number,
  parking: boolean,
  general_area: number,
  room_amount: number | null,
  bedroom_amount: number | null,
  storeroom: boolean,
  pets_allowed: boolean,
  rental_condition: null | string,
  purchase_price: number | null,
  purchase_date: null | Date,
  market_price: null | number,
  market_price_date: null | number,
  allow_registration_of_residence: boolean,
  rent_by_room: boolean,
  additional_information: null | string,
  build_year: null | number,
  renovation_year: null | number,
  heating_type: null | string,
  ventilation_type: null | string,
  mailbox_number: null | number,
  viewing_instructions: null | string,
  room_height: null | number,
  room_width: null | number,
  room_length: null | number,
  door_type: null | string,
  door_height: null | number,
  door_width: null | number,
  sub_object_type_names?: string[]
  buildingPlan?: {
      id?: number,
      file_name?: string,
      size?: string,
      name?: string,
      mime_type?: string,
      client_extension?: string,
      file_url?: string
  } | null,
  propertyPlan?: {
      id?: number,
      file_name?: string,
      size?: string,
      name?: string,
      mime_type?: string,
      client_extension?: string,
      file_url?: string
  } | null,
}

export interface EsoObject {
  objectId: number;
  objectNumber: string;
  objectName: string;
  objectType: string;
  objectAddress: string;
  permissiblePowerConsumptionV2: number;
  permissiblePowerGeneration: number;
  metersQty: number;
  smartMeterInstallationDate: string;
  voltage: number;
  productsQty: number;
  scalesQty: number;
  technologicalCosts: boolean;
  contract: unknown;
  supply: unknown;
  consumption: unknown;
  generatingObject: unknown;
  tariff: unknown;
}

export interface Device {
  id: string;
  authorized?: boolean;
  serialNumber: string;
  mac?: string | null;
  codeName: string;
  orgId?: string | null;
  propertyId?: string | null;
  hwRevisionId?: string | null;
  name?: string | null;
  alias?: string | null;
  location?: string | null;
  manufacturer?: string | null;
  description?: string | null;
  class?: string | null;
  model?: string | null;
  metadata?: string | null;
  swVersion?: string | null;
  osVersion?: string | null;
  createdAt: string;
  updatedAt: string;
}
export enum TechnicalConnectionType {
  MQTT = 'MQTT',
  OBIX = 'OBIX',
  BENCH = 'BENCH'
}
export const technicalConnectionTypes = [
  {
    value: TechnicalConnectionType.MQTT,
    text: 'Custom (MQTT)',
  },
  {
    value: TechnicalConnectionType.OBIX,
    text: 'Custom (oBIX)',
  },
  {
    value: TechnicalConnectionType.BENCH,
    text: 'Custom (Bench)',
  },
];
export interface TechnicalConnection {
  id: string;
  orgId: string;
  propertyId: string;
  deviceId: string;
  name: string;
  type: TechnicalConnectionType;
  data: Record<string, any>;
  createdAt: string;
  updatedAt: string;
}

export const measurementDeviceTypes = {
  [CollectionType.ANALYTICS]: [
    {
      value: 'current',
      text: 'Current',
      unit: 'A',
    },
    {
      value: 'power',
      text: 'Power',
      unit: 'kW',
    },
    {
      value: 'voltage',
      text: 'Voltage',
      unit: 'V',
    },
  ],
  [CollectionType.CLIMATE]: [
    {
      value: 'temperature',
      text: 'Temperature',
      unit: '°C',
    },
    {
      value: 'co2',
      text: 'CO₂',
      unit: 'ppm',
    },
    {
      value: 'co',
      text: 'CO',
      unit: 'ppm',
    },
    {
      value: 'voc',
      text: 'Volatile Organic Compound',
      unit: 'mg/m³',
    },
    {
      value: 'pressure',
      text: 'Pressure',
      unit: 'Pa',
    },
    {
      value: 'oxygen',
      text: 'Oxygen concentration',
      unit: '%',
    },
    {
      value: 'abs_humidity',
      text: 'Absolute Humidity',
      unit: 'g/m³',
    },
    {
      value: 'rel_humidity',
      text: 'Relative Humidity',
      unit: '%',
    },
    {
      value: 'occupancy',
      text: 'Occupancy',
      unit: 'units',
    },
  ],
  [CollectionType.METER]: [
    {
      value: 'warm_water',
      text: 'Warm water',
      unit: 'm³',
    },
    {
      value: 'cold_water',
      text: 'Cold water',
      unit: 'm³',
    },
    {
      value: 'heating',
      text: 'Heating',
      unit: 'kWh',
    },
    {
      value: 'cooling',
      text: 'Cooling',
      unit: 'kWh',
    },
    {
      value: 'electricity',
      text: 'Electricity',
      unit: 'kWh',
    },
    {
      value: 'electricity_day',
      text: 'Electricity (day)',
      unit: 'kWh',
    },
    {
      value: 'electricity_night',
      text: 'Electricity (night)',
      unit: 'kWh',
    },
    {
      value: 'electricity_weekend',
      text: 'Electricity (weekend)',
      unit: 'kWh',
    },
    {
      value: 'gas',
      text: 'Gas',
      unit: 'm³',
    },
  ],
  [CollectionType.CONSUMPTION]: [
    {
      value: 'warm_water',
      text: 'Warm water',
      unit: 'm³',
    },
    {
      value: 'cold_water',
      text: 'Cold water',
      unit: 'm³',
    },
    {
      value: 'heating',
      text: 'Heating',
      unit: 'kWh',
    },
    {
      value: 'cooling',
      text: 'Cooling',
      unit: 'kWh',
    },
    {
      value: 'electricity',
      text: 'Electricity',
      unit: 'kWh',
    },
    {
      value: 'electricity_day',
      text: 'Electricity (day)',
      unit: 'kWh',
    },
    {
      value: 'electricity_night',
      text: 'Electricity (night)',
      unit: 'kWh',
    },
    {
      value: 'electricity_weekend',
      text: 'Electricity (weekend)',
      unit: 'kWh',
    },
    {
      value: 'gas',
      text: 'Gas',
      unit: 'm³',
    },
  ],
  [CollectionType.ENERGY_PRICES]: [
    {
      value: 'electricity',
      text: 'Electricity',
      unit: 'EUR/MWh',
    },
  ],
  [CollectionType.WEATHER_FORECAST]: [
    {
      value: 'temperature',
      text: 'Temperature',
      unit: '°C',
    },
    {
      value: 'relative_humidity',
      text: 'Relative Humidity',
      unit: '%',
    },
    {
      value: 'dew_point',
      text: 'Dew Point',
      unit: '°C',
    },
    {
      value: 'apparent_temperature',
      text: 'Apparent Temperature',
      unit: '°C',
    },
    {
      value: 'precipitation_probability',
      text: 'Precipitation Probability',
      unit: '%',
    },
    {
      value: 'precipitation',
      text: 'Precipitation',
      unit: 'mm',
    },
    {
      value: 'rain',
      text: 'Rain',
      unit: 'mm',
    },
    {
      value: 'showers',
      text: 'Showers',
      unit: 'mm',
    },
    {
      value: 'snowfall',
      text: 'Snowfall',
      unit: 'mm',
    },
    {
      value: 'snow_depth',
      text: 'Snow Depth',
      unit: 'cm',
    },
    {
      value: 'surface_pressure',
      text: 'Surface Pressure',
      unit: 'hPa',
    },
    {
      value: 'visibility',
      text: 'Visibility',
      unit: 'km',
    },
    {
      value: 'wind_speed_10m',
      text: 'Wind Speed at 10m',
      unit: 'm/s',
    },
    {
      value: 'wind_direction_10m',
      text: 'Wind Direction at 10m',
      unit: '°',
    },
    {
      value: 'direct_radiation',
      text: 'Direct Radiation',
      unit: 'W/m²',
    },
    {
      value: 'cloud_cover',
      text: 'Cloud Cover',
      unit: '%',
    },
    {
      value: 'uv_index',
      text: 'UV Index',
      unit: '',
    },
  ],
};

export function measurementDeviceTypeUnit(collection: CollectionType, type: string) {
  const types = measurementDeviceTypes[collection];
  const t = types?.find((e) => e.value === type);
  return t?.unit ?? '';
}

export function collectionMeasurementDeviceTypes(collection: CollectionType) {
  const types = measurementDeviceTypes[collection] ?? [];
  return types.map((t) => ({ value: t.value, text: t.text }));
}

export const storeIntervals = [
  { value: 0, text: 'Do not store' },
  { value: 60, text: 'Every minute' },
  { value: 60 * 10, text: 'Every 10 minutes' },
  { value: 60 * 30, text: 'Every 30 minutes' },
  { value: 60 * 60, text: 'Every hour' },
  { value: 6 * 60 * 60, text: 'Every 6 hours' },
  { value: 12 * 60 * 60, text: 'Every 12 hours' },
  { value: 24 * 60 * 60, text: 'Every day' },
];

export const accessTypes = [
  { value: 'READ', text: 'Read only' },
  { value: 'READ_WRITE', text: 'Read/Write' },
  { value: 'WRITE', text: 'Write only' },
];

export const inputTypes = [
  { value: 'select', text: 'Select menu' },
  { value: 'radio', text: 'Radio buttons' },
  { value: 'number', text: 'Number' },
  { value: 'switch', text: 'On/Off Switch' },
  { value: 'push', text: 'Push Button' },
];

export interface VisualItem {
  id: string;
  technicalSystemId: string;
  technicalDataPointId: string;
  technicalDataPoint: any,
  label: string;
  s3objectkey: string;
  x: string;
  y: string;
  zIndex: number,
  width: string;
  height: string
}

export enum RuleEntity {
  MEASUREMENTS_ALARMS = 'measurements.alarms',
}

export interface Rule {
  id?: string;
  name?: string;
  entity: RuleEntity;
  enabled: boolean;
  propertyId: string;
  orgId: string;
  updatedAt: string;
  createdAt: string;
}

export enum RuleTriggerType {
  ENTITY_CREATED = 'entity_created',
  ENTITY_UPDATED = 'entity_updated',
  ENTITY_DELETED = 'entity_deleted',
}

export enum FilterOperator {
  greater_than = '$greater_than',
  greater_than_or_equal = '$greater_than_or_equal',
  less_than = '$less_than',
  less_than_or_equal = '$less_than_or_equal',
  in = '$in',
  not_in = '$not_in',
  equals = '$equals',
  not_equals = '$not_equals',
  contains = '$contains',
  between = '$between',
}

export interface FilteringStatement {
  field: string;
  operator: FilterOperator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export interface RuleTrigger {
  name: string,
  type: RuleTriggerType;
  data?: {
    filters?: FilteringStatement[]
  };
}

export enum RuleActionType {
  CHANGE_FIELD = 'change_field',
  CREATE_ENTITY = 'create_entity',
  CREATE_NOTIFICATION_SMS = 'create_notification_sms',
  CREATE_NOTIFICATION_EMAIL = 'create_notification_email',
  CREATE_NOTIFICATION_WEBHOOK = 'create_notification_webhook',
}

export interface RuleActionCreateNotificationEmailData {
  orgRoleId?: string; // one
  email?: string; // or the other
  messagingTemplateId: string;
}

export interface RuleActionCreateNotificationSMSData {
  phone: string;
  messagingTemplateId: string;
}

export interface RuleAction {
  name: string;
  type: RuleActionType;
  data: RuleActionCreateNotificationSMSData | RuleActionCreateNotificationEmailData;
  messagingTemplateId: string;
  messagingTemplate: { id: string };
}

export const RuleTriggerTypeNameMap: Record<string, string> = {
  [RuleTriggerType.ENTITY_CREATED]: 'Created',
  [RuleTriggerType.ENTITY_UPDATED]: 'Updated',
  [RuleTriggerType.ENTITY_DELETED]: 'Deleted',
};

export const RuleActionTypeNameMap:Record<string, string> = {
  // [RuleActionType.CHANGE_FIELD]: 'Change Field',
  // [RuleActionType.CREATE_ENTITY]: 'Create Entity',
  [RuleActionType.CREATE_NOTIFICATION_EMAIL]: 'Send e-mail',
  [RuleActionType.CREATE_NOTIFICATION_SMS]: 'Send SMS',
  // [RuleActionType.CREATE_NOTIFICATION_WEBHOOK]: 'Entity Deleted',
};

export interface Tag {
  id: string;
  name: string;
  number?: string;
  description?: string;
  parentId?: string;
  propertyId: string;
  orgId: string;
  updatedAt: string;
  createdAt: string;
}

export interface ExternalCompany {
  id: string;
  orgId: string;
  propertyId: string;
  externalId?: string | null;
  serviceConnectionId?: string | null;
  name: string;
  regno?: string | null;
  vatno?: string | null;
  zip?: string | null;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface ExternalPartner {
  id: string;
  orgId: string;
  propertyId: string;
  externalId?: string | null;
  serviceConnectionId?: string | null;
  cloudId: string;
  firstname: string;
  lastname: string;
  email: string;
  phone?: string | null;
  createdAt: string;
  updatedAt: string;
  externalCompany: ExternalCompany
}

export enum UnitType {
  OFFICE = 'office',
  RETAIL = 'retail',
  CONFERENCE_ROOM = 'conference_room',
  RECEPTION_AREA = 'reception_area',
  LOBBY = 'lobby',
  RESTROOM = 'restroom',
  BREAK_ROOM = 'break_room',
  STORAGE_ROOM = 'storage_room',
  TECHNICAL_ROOM = 'technical_room',
  RENTAL_SPACE = 'rental_space',
  GENERAL_AREA = 'general_area',
  PARKING_AREA = 'parking_area',
  HEALTH = 'health',
  FOOD_SERVICES = 'food_services',
  OUTDOOR_SPACE = 'outdoor_space',
  MEDICAL = 'medical',
  EDUCATIONAL_SPACE = 'educational_space',
  ENTERTAINMENT = 'entertainment',
  ATRIUM = 'atrium',
  CLEANING_ROOM = 'cleaning_room',
  LAUNDRY = 'laundry',
}

export function getUnitTypeIcon(type: UnitType) {
  switch (type) {
    case UnitType.ATRIUM:
      return 'users-viewfinder';
    case UnitType.BREAK_ROOM:
      return 'couch';
    case UnitType.CLEANING_ROOM:
      return 'broom';
    case UnitType.CONFERENCE_ROOM:
      return 'person-chalkboard';
    case UnitType.EDUCATIONAL_SPACE:
      return 'user-graduate';
    case UnitType.ENTERTAINMENT:
      return 'masks-theater';
    case UnitType.FOOD_SERVICES:
      return 'utensils';
    case UnitType.GENERAL_AREA:
    case UnitType.LOBBY:
      return 'people-roof';
    case UnitType.HEALTH:
      return 'spa';
    case UnitType.LAUNDRY:
      return 'soap';
    case UnitType.MEDICAL:
      return 'user-doctor';
    case UnitType.OFFICE:
      return 'briefcase';
    case UnitType.OUTDOOR_SPACE:
      return 'tree-city';
    case UnitType.PARKING_AREA:
      return 'square-parking';
    case UnitType.RECEPTION_AREA:
      return 'bell-concierge';
    case UnitType.RENTAL_SPACE:
      return 'person-shelter';
    case UnitType.RESTROOM:
      return 'restroom';
    case UnitType.RETAIL:
      return 'cart-shopping';
    case UnitType.STORAGE_ROOM:
      return 'box';
    case UnitType.TECHNICAL_ROOM:
      return 'gears';
    default:
      return 'person-shelter';
  }
}

export const UnitTypeValues = Object.values(UnitType).map((v) => ({ value: v, text: v.charAt(0).toUpperCase() + v.slice(1).replaceAll('_', ' ') }));

export interface Unit {
  id: string;
  orgId: string;
  propertyId: string;
  name: string;
  type: UnitType;
  dashboard: boolean;
}

export enum MeasurementDeviceFlowDirection {
  GRID_IMPORT = 'grid-import',
  GRID_EXPORT = 'grid-export',
  PRODUCER = 'producer',
  CONSUMER = 'consumer',
  STORAGE_IMPORT = 'storage-import',
  STORAGE_EXPORT = 'storage-export'
}

export const measurementDeviceFlowDirections = [
  { value: MeasurementDeviceFlowDirection.GRID_IMPORT, text: 'Import (Grid)' },
  { value: MeasurementDeviceFlowDirection.GRID_EXPORT, text: 'Export (Grid)' },
  { value: MeasurementDeviceFlowDirection.PRODUCER, text: 'Producer' },
  { value: MeasurementDeviceFlowDirection.CONSUMER, text: 'Consumer' },
  { value: MeasurementDeviceFlowDirection.STORAGE_IMPORT, text: 'Import (Storage)' },
  { value: MeasurementDeviceFlowDirection.STORAGE_EXPORT, text: 'Export (Storage)' },
];
export interface PropertyRole {
  id: string;
  cloudId: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  orgId: string;
  propertyId: string;
  role?: PropertyRolePermission;
  createdAt: string;
  updatedAt: string;
  serviceConnectionId?: string;
  externalId?: string;
  externalData?: Record<string, any>;
}
export enum ChartType {
  LINE = 'line',
  AREA = 'area',
  BAR = 'bar'
}
export const chartType = [
  { value: ChartType.LINE, text: 'Line' },
  { value: ChartType.AREA, text: 'Area' },
  { value: ChartType.BAR, text: 'Bar' },
];
export enum PeriodRange {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}

export const periodRanges = [
  { value: PeriodRange.DAY, text: 'Day' },
  { value: PeriodRange.MONTH, text: 'Month' },
  { value: PeriodRange.YEAR, text: 'Year' },
];

export const metricAggregations = [
  { value: MetricAggregations.avg, text: 'Average' },
  { value: MetricAggregations.min, text: 'Minimum' },
  { value: MetricAggregations.max, text: 'Maximum' },
  { value: MetricAggregations.sum, text: 'Sum' },
  { value: MetricAggregations.count, text: 'Count' },
];

export interface TechnicalSystemGraph {
  id: string;
  technicalSystemId: string;
  orgId: string;
  propertyId: string;
  name: string;
  description?: string | null;
  chartType: ChartType;
  defaultPeriodRange: PeriodRange;
  createdAt: string;
  updatedAt: string;
}

export interface TechnicalSystemGraphDataPoint {
  id: string;
  technicalSystemGraphId: string;
  orgId: string;
  propertyId: string;
  series: string;
  chartType?: ChartType;
  technicalDataPointId: string;
  aggregationFunction: MetricAggregations;
  createdAt: Date;
  updatedAt: Date;
}
